var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('companies.companies'),"description":_vm.$t('companies.fromHereYouCanControlYourOwnCompanies')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('companies.SearchForACompany'),"emptyTableText":_vm.$t('companies.thereAreNoCompanies'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.companies_add,"cloumns":[
          {
            column: 'name',
            title: _vm.$t('companies.companyName'),
            type: 'text',
            sort: true
          },
          {
            column: 'address1',
            title: _vm.$t('companies.companyAddress'),
            type: 'text',
            sort: true
          },
          {
            column: 'city',
            title: _vm.$t('companies.cityOfTheCompany'),
            type: 'text',
            sort: true
          },
          {
            column: 'country',
            title: _vm.$t('companies.countryOfTheCompany'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('companies.settings'),
            type: 'options',
            options: [
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.companies_edit
              },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.companies_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }