var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('pointOfSales.pointOfSales'),"description":_vm.$t('pointOfSales.pointOfSalesDescription')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('pointOfSales.FindAPointOfSale'),"emptyTableText":_vm.$t('pointOfSales.thereAreNopointOfSales'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.point_of_sales_add,"cloumns":[
          {
            column: 'name',
            title: _vm.$t('pointOfSales.name'),
            type: 'text',
            sort: true
          },
          {
            column: 'store_id',
            title: _vm.$t('pointOfSales.store'),
            type: 'link',
            to: 'store',
            sort: true,
            link: false
          },
          {
            column: 'safe_id',
            title: _vm.$t('pointOfSales.safe'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: false
          },
          {
            column: 'balance',
            title: _vm.$t('pointOfSales.balance'),
            type: 'text',
            sort: true,
            disable: !(this.$user.admin || this.$user.role.point_of_sales_show_balance) ? 1 : 0
          },
          {
            column: 'stat',
            title: _vm.$t('pointOfSales.active'),
            type: 'stat',
            sort: true
          },
          {
            column: 'password',
            title: _vm.$t('pointOfSales.DeviceCode'),
            type: 'text',
            sort: true
          },
          {
            column: 'session_number',
            title: _vm.$t('pointOfSales.SessionNo'),
            type: 'text',
            sort: true
          },

          {
            column: 'options',
            title: _vm.$t('pointOfSales.settings'),
            type: 'options',
            options: [
              { name: 'pointOfSale' },
              {
                name: 'pointOfSaleReport',
                role: _vm.$user.admin || _vm.$user.role.reports_show,
                title: 'تقرير المبيعات',
                icon: 'chart-pie',
                link: '/salesByItem?session_number= ' + +'&point_of_sale_id='
              },
              {
                name: 'newSession',
                role: _vm.$user.admin || _vm.$user.role.point_of_sales_edit
              },
              {
                name: 'posLink',
                role: _vm.$site.safesDeposits_allow && (_vm.$user.admin || _vm.$user.role.safes_deposits_add),
                title: _vm.$t('safes.DepositToTheSafe'),
                icon: 'donate',
                link: '/safesDeposits/create'
              },
              {
                name: 'posLink',
                role:
                  _vm.$site.safesWithdrawals_allow &&
                  (_vm.$user.admin || _vm.$user.role.safes_withdrawals_add),
                title: _vm.$t('safes.WithdrawalFromTheTreasury'),
                icon: 'money-bill-alt',
                link: '/safesWithdrawals/create'
              },
              {
                name: 'posLinkTransefere',
                role:
                  _vm.$site.safesTransfers_allow && (_vm.$user.admin || _vm.$user.role.safes_transfers_add),
                title: _vm.$t('safes.TransferFromTheTreasury'),
                icon: 'suitcase',
                link: '/safesTransfers/create'
              },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.point_of_sales_edit
              },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.point_of_sales_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)]),(_vm.changePointOfSale)?_c('changeSession'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }