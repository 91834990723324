var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'chart-line',"title":_vm.item.code,"buttons":[
        {
          title: _vm.$t('manufacturingOperations.show.edit'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$user.admin || _vm.$user.role.manufacturing_operations_edit
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('manufacturingOperations.show.basicInformation'),"cloumns":{
        firstCloum: [
          {
            icon: 'user',
            title: _vm.$t('manufacturingOperations.show.manufacturingCode'),
            value: _vm.item.code
          },
          {
            icon: 'calendar',
            title: _vm.$t('manufacturingOperations.show.StartDate'),
            value: _vm.$dateFormat(_vm.item.start_date)
          },
          {
            icon: 'money-bill',
            title: _vm.$t('manufacturingOperations.show.ExpectedCost'),
            value: _vm.item.expected_cost + ' ' + _vm.$option.currency
          },
          {
            icon: 'money-bill',
            title: _vm.$t('manufacturingOperations.show.Cost'),
            value: _vm.item.cost + ' ' + _vm.$option.currency
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('manufacturingOperations.show.earning'),
            value: _vm.item.price - _vm.item.cost + ' ' + _vm.$option.currency
          }
        ],
        scondCloum: [
          {
            icon: 'toggle-on',
            title: _vm.$t('manufacturingOperations.show.ProjectStatus'),
            value:
              _vm.item.stat == 1
                ? _vm.$t('manufacturingOperations.show.effective')
                : _vm.$t('manufacturingOperations.show.Ineffective')
          },
          {
            icon: 'calendar',
            title: _vm.$t('manufacturingOperations.show.EndDate'),
            value: _vm.$dateFormat(_vm.item.end_date)
          },
          {
            icon: 'money-bill-wave',
            title: _vm.$t('manufacturingOperations.show.ExpectedPrice'),
            value: _vm.item.expected_price + ' ' + _vm.$option.currency
          },
          {
            icon: 'money-bill',
            title: _vm.$t('manufacturingOperations.show.Price'),
            value: _vm.item.price + ' ' + _vm.$option.currency
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('manufacturingOperations.show.earningPresntage'),
            value: (((_vm.item.price - _vm.item.cost) / _vm.item.price) * 100).toFixed(1) + ' %'
          }
        ]
      }}}),_c('manufacturingDeposits',{staticClass:"col-12"}),_c('manufacturingWithdrawals',{staticClass:"col-12"})],1),_c('showFile')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }