var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('safesTransfers.safesTransfers'),"description":_vm.$t('safesTransfers.fromHereYouCanControlSafesTransfers')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('safesTransfers.searchForTransfer'),"emptyTableText":_vm.$t('safesTransfers.thereAreNoWithdrawalsOperations'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.safes_transfers_add,"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: '' },
          { title: _vm.$t('orders.all'), value: '' },
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('safesTransfers.operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'from_safe_id',
            title: _vm.$t('safesTransfers.fromSafeTransefrs'),
            element: 'fromSafe',
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'to_safe_id',
            title: _vm.$t('safesTransfers.toSafeTransefrs'),
            element: 'toSafe',
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('safesTransfers.transferDate'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('safesTransfers.thatsAbout'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: _vm.$t('safesTransfers.amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('invoices.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('invoices.approved'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('safesTransfers.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.safes_transfers_edit
              },
              { name: 'file' },
              { name: 'printSafesTransfer', role: true },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.safes_transfers_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }