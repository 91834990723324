var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('unitsCoefficients.unitsCoefficients'),"description":_vm.$t('unitsCoefficients.fromHereYouCanCntrolYourUnitTransactions')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('unitsCoefficients.parameterSearch'),"emptyTableText":_vm.$t('unitsCoefficients.thereAreNoTransactions'),"localData":true,"buttonRole":_vm.$user.role.products_add,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('unitsCoefficients.nameparameter'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: _vm.$t('units.settings'),
            type: 'options',
            options: [
              { name: 'edit', role: _vm.$user.admin || _vm.$user.role.products_edit },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.products_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }