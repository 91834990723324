var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('partnersProfits.PartnerDividendSplits'),"description":_vm.$t('partnersProfits.fromHereYouCanControlThepartnersProfits')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('partnersProfits.SearchForAProcess'),"emptyTableText":_vm.$t('partnersProfits.thereAreNopartnersProfits'),"emptyTableSubText":_vm.$t('partnersProfits.TryAddingSomeAndTryAgain'),"buttonRole":_vm.$user.role.safes_deposits_add,"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: '' },
          { title: _vm.$t('orders.all'), value: '' },
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('partnersProfits.reference'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'safe_id',
            title: _vm.$t('partnersProfits.Project'),
            type: 'link',
            to: 'project',
            sort: true,
            link: true
          },
          { column: 'date', title: _vm.$t('partnersProfits.paymentDate'), type: 'text', sort: true },
          { column: 'total', title: _vm.$t('partnersProfits.amount'), type: 'text', sort: true },
          {
            column: 'description',
            title: _vm.$t('partnersProfits.thatsAbout'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('invoices.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('invoices.approved'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('partnersProfits.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$user.admin || _vm.$user.role.safes_deposits_edit },
              { name: 'file' },
              { name: 'delete', role: _vm.$user.admin || _vm.$user.role.safes_deposits_delete }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }