var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('stores.stores'),"description":_vm.$t('stores.fromHereYouCanControlYourProductStores'),"explanationPath":_vm.path + '/explaination',"showBarcode":'true',"barcodePath":'productsBarcode'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('stores.SearchForAStore'),"emptyTableText":_vm.$t('stores.thereAreNoStores'),"buttonRole":_vm.$user.role.stores_add,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          { column: 'id', title: 'كود', type: 'mainLink', sort: true },
          {
            column: 'name',
            title: _vm.$t('stores.storeName'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'country',
            title: _vm.$t('stores.country'),
            type: 'text',
            sort: true,
            icon: 'city'
          },
          {
            column: 'city',
            title: _vm.$t('stores.city'),
            type: 'text',
            sort: true,
            icon: 'building'
          },
          {
            column: 'stat',
            title: _vm.$t('stores.active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('stores.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$user.admin || _vm.$user.role.stores_edit },
              {
                name: 'link',
                role:
                  _vm.$site.storesTransfers_allow && (_vm.$user.admin || _vm.$user.role.stores_transfers_add),
                title: _vm.$t('stores.MoveFromTheStore'),
                icon: 'suitcase',
                link: '/storesTransfers/create?from_store_id='
              },
              {
                name: 'link',
                role:
                  _vm.$site.storesDeposits_allow && (_vm.$user.admin || _vm.$user.role.stores_deposits_add),
                title: _vm.$t('stores.DepositToTheStore'),
                icon: 'donate',
                link: '/storesDeposits/create?store_id='
              },
              {
                name: 'link',
                role:
                  _vm.$site.storesWithdrawals_allow &&
                  (_vm.$user.admin || _vm.$user.role.stores_withdrawals_add),
                title: _vm.$t('stores.EnglishPullOutOfTheStore'),
                icon: 'money-bill-alt',
                link: '/storesWithdrawals/create?store_id='
              },
              {
                name: 'moveStore',
                role:
                  _vm.$site.storesTransfers_allow && (_vm.$user.admin || _vm.$user.role.stores_transfers_add),
                title: 'نقل كامل البضاعة الي مخزن',
                icon: 'suitcase'
              },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.stores_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }