var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('saleReturns.invoices'),"description":_vm.$t('saleReturns.fromHereYouCanControlSalesInvoices')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('saleReturns.searchForAnInvoice'),"emptyTableText":_vm.$t('saleReturns.thereAreNoBills'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.sale_returns_add,"statusSearch":true,"statusValues":[
          { title: _vm.$t('quotations.situation'), value: '' },
          { title: _vm.$t('quotations').all, value: '' },
          { title: _vm.$t('saleReturns').draft, value: 0 },
          { title: _vm.$t('saleReturns').sent, value: 1 },
          { title: _vm.$t('saleReturns').seen, value: 2 },
          { title: _vm.$t('saleReturns').driven, value: 3 },
          { title: _vm.$t('saleReturns').canceled, value: 4 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('saleReturns.invoice'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('saleReturns.theDateOfTheInvoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('invoices.customerName'),
            type: 'clintLink',
            sort: false
          },
          {
            column: 'total',
            title: _vm.$t('saleReturns.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'rest',
            title: _vm.$t('saleReturns.balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('saleReturns.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('saleReturns.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('saleReturns.sent'), value: 1, color: 'primary' },
              { title: _vm.$t('saleReturns.seen'), value: 2, color: 'primary' },
              { title: _vm.$t('saleReturns.driven'), value: 3, color: 'success' },
              {
                title: _vm.$t('saleReturns.canceled'),
                value: 4,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('saleReturns.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.sale_returns_edit
              },
              { name: 'file' },
              { name: 'outLink', role: true },
              { name: 'printSaleReturn', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.sale_returns_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }