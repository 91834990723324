<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{ $t('components.partners.WithdrawalOfPartners') }}</h4>
        <router-link
          :to="'/partnersWithdrawals/create?partner_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
        >
          <i class="fas fa-plus"></i> {{ $t('components.partners.addition') }}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('partnersWithdrawals.SearchForAnOperation')"
        :emptyTableText="$t('partnersWithdrawals.thereAreNoWithdrawalsOperations')"
        :filter="{ partner_id: $route.params.id }"
        :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
        :withoutCard="true"
        :cloumns="[
          {
            column: 'code',
            title: $t('partnersWithdrawals.Process'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'partner_id',
            title: $t('partnersWithdrawals.PartnerName'),
            type: 'link',
            to: 'partner',
            sort: true,
            link: true
          },
          {
            column: 'safe_id',
            title: $t('partnersWithdrawals.Treasury'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: $t('partnersWithdrawals.date'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: $t('partnersWithdrawals.AndThatIsAbout'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: $t('partnersWithdrawals.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'options',
            title: $t('partnersWithdrawals.actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: $user.admin || $user.role.purchase_payments_edit
              },
              { name: 'printPurchasePayment', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: $user.admin || $user.role.purchase_payments_delete
              }
            ]
          }
        ]"
        :deleteText="{
          attention: $t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
          close: $t('allerts.close'),
          confirm: $t('allerts.confirm'),
          loading: $t('allerts.loading')
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/partnersWithdrawals'
    }
  },
  components: {
    indexTable
  }
}
</script>
