var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"print-model"},[_c('div',{ref:"printMe",attrs:{"id":"printMe"}},_vm._l((_vm.$parent.options.quotationItems),function(item,index){return _c('div',{key:index},_vm._l((parseFloat(item.quantity > 0 ? item.quantity : 0)),function(i,index){return _c('div',{key:index},[_c('div',{staticClass:"sticer2",style:('height: ' +
            _vm.$parent.options.height +
            'mm;width: ' +
            _vm.$parent.options.width +
            'mm;text-align:center;font-weight:800;padding: ' +
            _vm.$parent.options.height_padding +
            'mm ' +
            _vm.$parent.options.width_padding +
            'mm;box-sizing: border-box;')},[_c('div',{staticClass:"product-name",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(item.product_code ? item.product_code : '--')+" "),_c('br'),_vm._v(" "+_vm._s(item.product_name ? item.product_name : '--')+" ")]),_c('barcode',{staticClass:"barcode",style:('margin-top: 2px;margin-bottom: 2px;height: ' +
              ((_vm.$parent.options.height - _vm.$parent.options.height_padding * 2) * 3.7795275591 -
                50) +
              'px'),attrs:{"value":item.barcode,"font-size":"0","height":(_vm.$parent.options.height - _vm.$parent.options.height_padding * 2) * 3.7795275591 -
              70 +
              'px',"width":"2px","margin-top":"-4","margin-bottom":"-4"}}),_c('div',{style:('letter-spacing: 5px;font-size: 13px;margin-bottom: -3px;margin-top: -24px;')},[_vm._v(" "+_vm._s(item.barcode)+" ")]),(_vm.$option.barcode_company_show)?_c('div',{staticClass:"product-price",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$option.name)+" ")]):_vm._e(),(_vm.$option.barcode_price_show)?_c('div',{staticClass:"product-price",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.price)+" "+_vm._s(_vm.$option.currency)+" ")]):_vm._e()],1)])}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }