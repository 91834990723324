var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('sections.sections'),"description":_vm.$t('sections.fromHereYouCanControlProductSections')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('sections.SearchForARating'),"emptyTableText":_vm.$t('sections.thereAreNoCategories'),"buttonRole":_vm.$user.role.products_add,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('sections.sectionName'),
            type: 'text',
            sort: true
          },
          {
            column: 'parent_id',
            title: _vm.$t('sections.fatherSection'),
            type: 'link',
            to: 'section',
            sort: true,
            link: false
          },
          {
            column: 'options',
            title: _vm.$t('sections.settings'),
            type: 'options',
            options: [
              { name: 'edit', role: _vm.$user.admin || _vm.$user.role.products_edit },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.products_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }