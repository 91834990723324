var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'user-cog',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('employees.show.active') : _vm.$t('employees.show.inactive'),"subData":[
        { title: _vm.$t('employees.show.Title'), value: _vm.item.job_title },
        {
          title: _vm.$t('employees.show.Salary'),
          value: _vm.$moneyFormat(_vm.item.salary + ' ' + _vm.$option.currency)
        }
      ],"buttons":[
        {
          title: _vm.$t('employees.show.modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$user.admin || _vm.$user.role.empolyees_edit
        }
      ]}}),_c('div',{staticClass:"col-12"},[_c('showFile')],1),(_vm.$site.employeesSalaries_allow && (_vm.$user.admin || _vm.$user.role.employees_salaries_show))?_c('employeesSalaries',{staticClass:"col-12"}):_vm._e(),(
        _vm.$site.employeesIncentives_allow && (_vm.$user.admin || _vm.$user.role.employees_incentives_show)
      )?_c('employeesIncentives',{staticClass:"col-12"}):_vm._e(),(
        _vm.$site.employeesDeductions_allow && (_vm.$user.admin || _vm.$user.role.employees_deductions_show)
      )?_c('employeesDeductions',{staticClass:"col-12"}):_vm._e(),(
        _vm.$site.employeesWithdrawals_allow && (_vm.$user.admin || _vm.$user.role.employees_withdrawals_show)
      )?_c('employeesWithdrawals',{staticClass:"col-12"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }