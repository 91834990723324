<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('partnersProfits.PartnerDividendSplits')"
          :description="$t('partnersProfits.fromHereYouCanControlThepartnersProfits')"
        />
        <indexTable
          :searchText="$t('partnersProfits.SearchForAProcess')"
          :emptyTableText="$t('partnersProfits.thereAreNopartnersProfits')"
          :emptyTableSubText="$t('partnersProfits.TryAddingSomeAndTryAgain')"
          :buttonRole="$user.role.safes_deposits_add"
          :statusSearch="true"
          :statusValues="[
            { title: $t('orders.situation'), value: '' },
            { title: $t('orders.all'), value: '' },
            { title: $t('invoices.draft'), value: 0 },
            { title: $t('invoices.approved'), value: 1 }
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('partnersProfits.reference'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'safe_id',
              title: $t('partnersProfits.Project'),
              type: 'link',
              to: 'project',
              sort: true,
              link: true
            },
            { column: 'date', title: $t('partnersProfits.paymentDate'), type: 'text', sort: true },
            { column: 'total', title: $t('partnersProfits.amount'), type: 'text', sort: true },
            {
              column: 'description',
              title: $t('partnersProfits.thatsAbout'),
              type: 'text',
              sort: true
            },
            {
              column: 'user_name',
              title: 'اسم المسئول',
              type: 'text',
              sort: true,
              icon: 'usd-square'
            },
            {
              column: 'status',
              title: $t('bills.situation'),
              type: 'status',
              sort: true,
              values: [
                { title: $t('invoices.draft'), value: 0, color: 'dark' },
                { title: $t('invoices.approved'), value: 1, color: 'success' }
              ]
            },
            {
              column: 'options',
              title: $t('partnersProfits.settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'edit', role: $user.admin || $user.role.safes_deposits_edit },
                { name: 'file' },
                { name: 'delete', role: $user.admin || $user.role.safes_deposits_delete }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'

export default {
  data() {
    return {
      path: '/partnersProfits'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
