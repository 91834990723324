var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid shipping-table"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"ml-auto"},[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('shippingOperations.shippingOperations'))+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('shippingOperations.FromHereYouCanControlShipments'))+" ")])]),_c('router-link',{staticClass:"btn btn-primary add-btn",attrs:{"to":'/pointofsales/' + this.$route.params.id}},[_c('i',{staticClass:"fas fa-cash-register mr-2"}),_vm._v(" "+_vm._s(_vm.$t('pointOfSales.invoices.PointOfSaleOffer'))+" ")])],1)]),_c('div',{staticClass:"col-lg-9"},[_c('indexTable',{key:_vm.reload,class:'box-table',attrs:{"searchText":_vm.$t('shippingOperations.searchForOperation'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('shippingOperations.orderStatus'), value: '' },
          { title: _vm.$t('shippingOperations.beingProcessed'), value: 0 },
          { title: _vm.$t('shippingOperations.delivery'), value: 1 },
          { title: _vm.$t('shippingOperations.paid'), value: 2 }
        ],"emptyTableText":_vm.$t('shippingOperations.NoShipments'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"filter":{ point_of_sale_id: _vm.$route.params.id },"localDatabaseTable":"shippingOperations","timeTable":true,"deleteRoute":"/shippingOperations","table":"shippingOperations","withoutAdd":true,"popUpMargin":true,"cloumns":[
          {
            column: 'customer_id',
            title: _vm.$t('shippingOperations.customerName'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: false
          },
          {
            column: 'date',
            title: _vm.$t('shippingOperations.date'),
            type: 'text',
            sort: true
          },
          {
            column: 'out_date',
            title: _vm.$t('shippingOperations.outDate'),
            type: 'text',
            sort: true
          },
          {
            column: 'invoice_id',
            title: _vm.$t('shippingOperations.invoiceCode'),
            type: 'linkInvoice',
            to: 'invoice',
            sort: true,
            link: false
          },
          {
            column: 'address1',
            title: _vm.$t('shippingOperations.addres1'),
            type: 'text',
            sort: true
          },
          {
            column: 'total',
            title: _vm.$t('pointOfSales.invoices.total'),
            type: 'text',
            sort: true
          },
          {
            column: 'delivry_id',
            title: _vm.$t('pointOfSales.invoices.DeliveryRepresentative'),
            type: 'link',
            to: 'delivery',
            sort: true,
            link: false
          },
          {
            column: 'status',
            title: _vm.$t('shippingOperations.orderStatus'),
            type: 'status',
            sort: true,
            values: [
              {
                title: _vm.$t('shippingOperations.beingProcessed'),
                value: 0,
                color: 'dark'
              },
              {
                title: _vm.$t('shippingOperations.delivery'),
                value: 1,
                color: 'primary'
              },
              {
                title: _vm.$t('shippingOperations.paid'),
                value: 2,
                color: 'success'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('shippingOperations.settings'),
            type: 'options',
            options: [
              { name: 'showShipping' },
              { name: 'shippingPay' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.shippingOperations_edit
              },
              { name: 'printShippingOperation', role: true },
              { name: 'delivaryPointOfSale', role: true },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.shippingOperations_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-rocket"}),_vm._v(_vm._s(_vm.$t('shippingOperations.availablePilots'))+" ")])]),_c('div',{staticClass:"table-responsive"},[_vm._l((_vm.$deliveries[_vm.$route.params.id]
              .filter((el) => !el.onWay)
              .sort(this.$dynamicSort({ orderBy: 'inDate', orderType: 'desc' }))),function(delivary,index){return _c('div',{key:index,staticClass:"delivery-container"},[_c('div',{staticClass:"delivary-item"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(delivary.name))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$dateFormat(delivary.inDate, 'time')))])]),_c('div',{staticClass:"badge badge-success"},[_vm._v(" "+_vm._s(_vm.$t('shippingOperations.effective'))+" ")])])}),(_vm.$deliveries[_vm.$route.params.id].filter((el) => !el.onWay).length == 0)?_c('div',{staticClass:"pos-sidebar-empty-table delivary-empty"},[_vm._v(" "+_vm._s(_vm.$t('shippingOperations.noDelegatesAvailable'))+" ")]):_vm._e()],2)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"far fa-shipping-fast"}),_vm._v(_vm._s(_vm.$t('shippingOperations.theMessengersAreOnTheWay'))+" ")])]),_c('div',{staticClass:"table-responsive"},[_vm._l((_vm.$deliveries[_vm.$route.params.id]
              .filter((el) => el.onWay)
              .sort(this.$dynamicSort({ orderBy: 'outDate', orderType: 'asc' }))),function(delivary,index){return _c('div',{key:index,staticClass:"delivery-container"},[_c('div',{staticClass:"delivary-item"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(delivary.name))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$dateFormat(delivary.outDate, 'time')))])]),_c('div',{staticClass:"badge badge-primary"},[_vm._v(" "+_vm._s(_vm.$t('shippingOperations.onMyWay'))+" ")]),_c('button',{staticClass:"btn btn-light returned-btn",attrs:{"title":"$t('shippingOperations.arrival')"},on:{"click":function($event){return _vm.$returnDelivary(_vm.$route.params.id, delivary.id)}}},[_c('i',{staticClass:"fas fa-undo"})])])}),(_vm.$deliveries[_vm.$route.params.id].filter((el) => el.onWay).length == 0)?_c('div',{staticClass:"pos-sidebar-empty-table delivary-empty"},[_vm._v(" "+_vm._s(_vm.$t('shippingOperations.thereAreNoDelegatesOutside'))+" ")]):_vm._e()],2)])])]),(_vm.changeShippingOperation != null)?_c('changeDelivary',{attrs:{"ofline":_vm.ofline}}):_vm._e(),(_vm.payShippingOperation != null)?_c('payDelivary',{attrs:{"ofline":_vm.ofline}}):_vm._e(),(_vm.showShippingOp)?_c('shippingShow'):_vm._e(),(_vm.show)?_c('invoiceShow'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }