var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'treasure-chest',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('bankAccounts.show.active') : _vm.$t('bankAccounts.show.inactive'),"subData":[
        {
          title: _vm.$t('bankAccounts.show.currentBalance'),
          value: this.$moneyFormat(_vm.item.balance)
        }
      ],"buttons":[
        {
          title: _vm.$t('bankAccounts.show.modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$user.admin || _vm.$user.role.safes_edit
        }
      ]}}),_c('showBoxs',{staticClass:"col-12",attrs:{"boxs":[
        {
          icon: 'dollar-sign',
          title: _vm.$t('bankAccounts.show.currentBalance'),
          value: this.$moneyFormat(_vm.item.balance),
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('bankAccounts.show.invoicesCount'),
          value: _vm.item.salesPaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('bankAccounts.show.quotationsCount'),
          value: _vm.item.purchasePaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'usd-square',
          title: _vm.$t('bankAccounts.show.ordersCount'),
          value: _vm.item.expensesCount,
          class: 'col-md-3'
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('bankAccounts.show.accountInfo'),"cloumns":{
        firstCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('bankAccounts.show.Balance'),
            value: this.$moneyFormat(_vm.item.balance)
          }
        ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('bankAccounts.show.OpeningBalance'),
            value: this.$moneyFormat(_vm.item.opening_balance)
          }
        ]
      }}}),(_vm.$site.salesPayments_allow && (_vm.$user.admin || _vm.$user.role.sales_payments_show))?_c('salesPayments',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.purchasePayments_allow && (_vm.$user.admin || _vm.$user.role.purchase_payments_show))?_c('purchasepayement',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.salesCheques_allow && (_vm.$user.admin || _vm.$user.role.sales_cheques_show))?_c('salesCheques',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.purchaseCheques_allow && (_vm.$user.admin || _vm.$user.role.purchase_cheques_show))?_c('purchaseCheques',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.expenses_allow && (_vm.$user.admin || _vm.$user.role.expenses_show))?_c('expenses',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.safesDeposits_allow && (_vm.$user.admin || _vm.$user.role.safes_deposits_show))?_c('safesDeposits',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.safesWithdrawals_allow && (_vm.$user.admin || _vm.$user.role.safes_withdrawals_show))?_c('safesWithdrawals',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.safesTransfers_allow && (_vm.$user.admin || _vm.$user.role.safes_transfers_show))?_c('fromSafesTransfers',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$site.safesTransfers_allow && (_vm.$user.admin || _vm.$user.role.safes_transfers_show))?_c('toSafesTransfers',{staticClass:"col-lg-12"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }