var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('shippingAddresses.shippingAddresses'),"description":_vm.$t('shippingAddresses.fromHereYouControlTheShippingAddresses')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('shippingAddresses.searchAddress'),"emptyTableText":_vm.$t('customers.index.thereAreNoCustomers'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.admin || _vm.$user.role.customers_add,"localData":true,"cloumns":[
          {
            column: 'customer_id',
            title: _vm.$t('shippingOperations.customerName'),
            type: 'link',
            to: 'customer',
            sort: true,
            link: true
          },
          {
            column: 'address1',
            title: _vm.$t('shippingAddresses.theAddress'),
            type: 'text',
            sort: true,
            icon: 'map-marker-alt'
          },
          {
            column: 'mobile',
            title: _vm.$t('shippingAddresses.mobile'),
            type: 'text',
            sort: true,
            icon: 'phone'
          },
          {
            column: 'city_id',
            title: _vm.$t('shippingAddresses.city'),
            type: 'link',
            to: 'city',
            sort: true,
            link: false,
            icon: 'city'
          },
          {
            column: 'options',
            title: _vm.$t('customers.index.actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.customers_edit
              },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.customers_delete
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }